import React, {
  Component
} from 'react'
import firebase from '../config/Firebase'
import {
  NavLink,
  Link,
  withRouter
} from 'react-router-dom'
import Profile from './Profile'
import ProfileImage from './Profile/ProfileImage'
import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'

let itemsCollection

const keyFields = {
  cases: 'title',
  people: 'fields.lastname.value',
  companies: 'title'
}

class Listing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: false,
      loadedListId: false,
      subscription: false
    }
  }

  componentDidMount() {
    this.subscribeToList()
  }

  componentDidUpdate(prevProps) {
    const prevFrame = prevProps.Frame
    const thisFrame = this.props.Frame
    if (
      prevFrame.listId !== thisFrame.listId ||
      prevFrame.categoryId !== thisFrame.categoryId ||
      prevFrame.teamId !== thisFrame.teamId
    ) {
      this.subscribeToList()
    }
  }

  subscribeToList() {
    if (this.state.subscription) {
      this.unsubscribeFromList()
    }

    const {
      teamId,
      listId,
      categoryId
    } = this.props.Frame
    itemsCollection = firebase.firestore().collection(`teams/${teamId}/lists/${listId}/items`)

    const subscriptionPath = categoryId ?
      itemsCollection.where('categories', 'array-contains', categoryId) :
      itemsCollection

    const subscription = subscriptionPath.orderBy('title').onSnapshot(snapshot => {
      const items = []
      snapshot.forEach(doc => {
        const item = {
          ...doc.data(),
          id: doc.id
        }
        items.push(item)
      })
      this.setState({
        items,
        loadedListId: this.props.Frame.listId
      })
      // TODO: Update von Firestore in state.openItem einfließen lassen
    })

    this.setState({
      subscription
    })
  }

  unsubscribeFromList() {
    this.state.subscription()
    this.setState({
      items: false,
      subscription: false,
      loadedListId: false
    })
  }

  handleChangedItem(item) {
    if (item.id) {
      this.updateExistingItem(item)
    } else {
      this.createNewItemAndOpenIt(item)
    }
  }

  updateExistingItem(item) {
    const {
      teamId,
      listId,
      categoryId,
      goTo
    } = this.props.Frame

    const prevData = this.state.items.filter(x => x.id === item.id)[0]
    var newData = Object.assign({}, item)

    console.log('prevData: ' + JSON.stringify(prevData))
    console.log('newData: ' + JSON.stringify(newData))

    if (!_.isEqual(prevData, newData)) {
      delete newData.id
      itemsCollection.doc(item.id).update(newData)

      const deletedCategories = _.difference(_.get(prevData, 'categories', []), _.get(newData, 'categories', []))
      if (categoryId && deletedCategories.indexOf(categoryId) !== -1) {
        goTo(`/team/${teamId}/${listId}:${categoryId}`)
      }
    }
  }

  createNewItemAndOpenIt(item) {
    itemsCollection
      .add(item)
      .then(doc => {
        this.props.history.push(`/team/${this.props.Frame.teamId}/${this.props.Frame.listId}/${doc.id}`)
      })
      .catch(error => {
        console.warn(error)
      })
  }

  deleteItem(itemId) {
    this.props.history.push(`/team/${this.props.Frame.teamId}/${this.props.Frame.listId}`)

    itemsCollection
      .doc(itemId)
      .delete()
      .catch(error => {
        console.warn(error.message)
      })
  }

  getItemsInAlphabeticalOrder() {
    let obj = {}
    if (this.state.items.length > 0 && this.state.loadedListId === this.props.Frame.listId) {
      const keyField = keyFields[this.props.Frame.listId]
      const items = _.cloneDeep(this.state.items)
      items.sort((obj1, obj2) => {
        return _.get(obj1, keyField, '')
          .toLowerCase()
          .localeCompare(_.get(obj2, keyField, '').toLowerCase())
      })
      items.forEach(function (item) {
        const keyFieldData = _.get(item, keyField, '')
        if (keyFieldData) {
          const firstLetter = keyFieldData.substr(0, 1).toUpperCase()
          if (typeof obj[firstLetter] !== 'undefined') {
            obj[firstLetter].push(item)
          } else {
            obj[firstLetter] = [item]
          }
        } else {
          console.warn('no keyField found', item)
        }
      }, this)
    }
    return obj
  }

  openNextListingItem() {
    const currentIndex = this.state.items.findIndex(item => item.id === this.props.Frame.itemId)
    if (currentIndex + 1 < this.state.items.length) {
      const nextItemId = this.state.items[currentIndex + 1].id
      this.props.history.push(`/team/${this.props.Frame.teamId}/${this.props.Frame.listId}/${nextItemId}`)
    }
  }

  openPrevListingItem() {
    const currentIndex = this.state.items.findIndex(item => item.id === this.props.Frame.itemId)
    if (currentIndex > 0) {
      const prevItemId = this.state.items[currentIndex - 1].id
      this.props.history.push(`/team/${this.props.Frame.teamId}/${this.props.Frame.listId}/${prevItemId}`)
    }
  }

  render() {
      const Frame = this.props.Frame
      const listIsLoaded = this.state.loadedListId === Frame.listId
      const sortedItems = this.getItemsInAlphabeticalOrder()
      const lenghtOfList = Object.keys(sortedItems).length
      let item = this.state.items ? _.cloneDeep(this.state.items.filter(item => item.id === Frame.itemId)[0]) : false
      const DayString = props =>
        moment(props.date.toDate()).calendar(null, {
          lastDay: '[yesterday]',
          sameDay: '[today]',
          nextDay: '[tomorrow]',
          lastWeek: '[last] dddd',
          nextWeek: 'dddd',
          sameElse: 'll'
        })
      const listUrl = [Frame.listId, Frame.categoryId].filter(v => typeof v !== 'undefined').join(':')
      return ( <
        React.Fragment >
        <
        div className = {
          classNames('Listing', {
            'Listing--open-profile': Frame.itemId
          })
        } >
        <
        React.Fragment >
        <
        div className = "Listing__head" >
        <
        span className = "Listing__head__cell Listing__head__cell--title" > Name < /span> <
        span className = "Listing__head__cell Listing__head__cell--note" > Last Note < /span> <
        span className = "Listing__head__cell Listing__head__cell--followup" > Next Follow - up < /span> < /
        div > {
          listIsLoaded && lenghtOfList > 0 && ( <
            div className = "Listing__body" > {
              Object.keys(sortedItems).map(letter => ( <
                  React.Fragment key = {
                    letter
                  } >
                  <
                  h2 className = "Listing__separator" >
                  <
                  span className = "Listing__separator__letter" > {
                    letter
                  } < /span> < /
                  h2 > {
                    sortedItems[letter].map((item, i) => ( <
                        NavLink className = "Listing__contact"
                        to = {
                          `/team/${Frame.teamId}/${listUrl}/${item.id}`
                        }
                        key = {
                          item.id
                        }
                        activeClassName = "active" >
                        <
                        span className = "Listing__avatar" >
                        <
                        ProfileImage size = {
                          48
                        }
                        imageUrl = {
                          item.photo && item.photo.thumb64 ? item.photo.thumb64 : false
                        }
                        initials = {
                          item.initials
                        }
                        /> < /
                        span > <
                        span className = "Listing__cell Listing__title" > {
                          titleCell(item, Frame.listId)
                        } < /span> {
                        item.lastNote ? ( <
                          span className = {
                            `Listing__cell Listing__note Listing__note--${item.lastNote.type}`
                          } >
                          <
                          h3 className = "Listing__note__title" > {
                            item.lastNote.title
                          } < /h3> <
                          p className = "Listing__note__body" > {
                            item.lastNote.body
                          } < /p> <
                          p className = "Listing__note__meta" >
                          <
                          DayString date = {
                            item.lastNote.createdAt
                          }
                          /> by{' '} {
                          _.get(Frame, `team.members[${item.lastNote.createdBy}].name`, 'an unknown team mate')
                        } <
                        /p> < /
                        span >
                      ): ( <
                        span className = "Listing__cell Listing__note" / >
                      )
                    } {
                      item.nextFollowUp ? ( <
                        span className = "Listing__cell Listing__followup" >
                        <
                        h3 className = "Listing__followup__title" > {
                          item.nextFollowUp.title
                        } < /h3> <
                        p className = "Listing__followup__meta" >
                        for {
                          item.nextFollowUp.assignee.label
                        }
                        due < DayString date = {
                          item.nextFollowUp.dueDate
                        }
                        /> < /
                        p > <
                        /span>
                      ) : ( <
                        span className = "Listing__cell Listing__followup" / >
                      )
                    } <
                    /NavLink>
                  ))
              } <
              /React.Fragment>
            ))
        } <
        /div>
      )
    } <
    /React.Fragment> {
  listIsLoaded && lenghtOfList === 0 && ( <
    div className = "Listing__emptystate" >
    <
    div className = {
      `Listing__emptystate__content--${Frame.listId}`
    } >
    <
    h2 className = "Listing__emptystate__title" > So much potential! < /h2> <
    p className = "Listing__emptystate__text" >
    A new adventure is always exciting, right ? But don 't hesitate to take your first step. We all had to
    begin somewhere. <
    /p> <
    Link to = {
      `/team/${Frame.teamId}/${listUrl}/new`
    }
    className = "Listing__emptystate__cta" >
    Add your first {
      Frame.listId === 'cases' && 'case'
    } {
      Frame.listId === 'people' && 'person'
    } {
      Frame.listId === 'companies' && 'company'
    } <
    /Link> < /
    div > <
    /div>
  )
} <
/div> <
Profile Frame = {
  Frame
}
item = {
  item
}
saveItemToCollection = {
  e => this.handleChangedItem(e)
}
deleteItem = {
  itemId => this.deleteItem(itemId)
}
/> < /
React.Fragment >
)
}
}

function titleCell(item, listId) {
  let elem
  switch (listId) {
    case 'cases':
      elem = ( <
        div >
        <
        strong > {
          item.fields.name.value
        } < /strong> < /
        div >
      )
      break
    case 'people':
      elem = ( <
        React.Fragment >
        <
        div > {
          _.get(item, 'fields.firstname.value')
        } < strong > {
          _.get(item, 'fields.lastname.value')
        } < /strong> < /
        div > <
        div className = "Listing__cell__subline" > {
          _.get(item, 'fields.company.value.label')
        } < /div> < /
        React.Fragment >
      )
      break
    case 'companies':
      elem = ( <
        div >
        <
        strong > {
          item.fields.companyname.value
        } < /strong> < /
        div >
      )
      break
    default:
      elem = < div / >
        break
  }
  return elem
}

export default withRouter(Listing)
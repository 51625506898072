import React, { Component } from 'react'
import { Account, Categories, Sync, Teams } from './SettingsPages'
import Layer from './Utils/Layer'

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'account'
    }
  }

  openSection = id => {
    this.setState({
      activeTab: id
    })
  }

  render() {
    const layerSection = this.props.Frame.layerSection
    return (
      <Layer
        title="Settings"
        id="settings"
        tabs={[
          {
            id: 'account',
            icon: 'account',
            label: 'Account'
          },
          {
            id: 'sync',
            icon: 'sync',
            label: 'Sync'
          },
          {
            id: 'categories',
            icon: 'account',
            label: 'Categories'
          },
          {
            id: 'teams',
            icon: 'teams',
            label: 'Teams'
          }
        ]}
        content={
          <>
            {layerSection === 'account' && <Account {...this.props} />}
            {layerSection === 'sync' && <Sync {...this.props} />}
            {layerSection === 'categories' && <Categories {...this.props} />}
            {layerSection === 'teams' && <Teams {...this.props} />}
          </>
        }
        Frame={this.props.Frame}
      />
    )
  }
}
export default Settings

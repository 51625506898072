import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ProfileImage from './Profile/ProfileImage'
import Lists from '../config/Lists'
import Fields from '../config/Fields'
import ContactDetails from './Profile/ContactDetails'
import ContactEditor from './Profile/ContactEditor'
import FollowUps from './Profile/FollowUps'
import Notes from './Profile/Notes'
import _ from 'lodash'

class Profile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'profile',
      inEdit: this.props.Frame.itemId === 'new' ? true : false,
      editedItem: {}
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const itemHasChanged = !_.isEqual(this.props.Frame.itemId, prevProps.Frame.itemId)
    if (itemHasChanged) {
      if (this.props.Frame.itemId === 'new') {
        this.setState({
          inEdit: true,
          editedItem: {}
        })
      } else {
        this.setState({ inEdit: false })
      }
    }
    this.setTitle()
  }

  editItem = () => {
    this.setState({
      inEdit: true,
      editedItem: _.cloneDeep(this.props.item)
    })
  }

  saveItem = () => {
    const { title, initials } = calculateTitleAndInitials(this.state.editedItem, this.props.Frame.listId)
    this.props.saveItemToCollection({ ...this.state.editedItem, title, initials })
    this.setState({ inEdit: false, editedItem: false })
  }

  discardEdit = () => {
    const { teamId, listId, itemId } = this.props.Frame
    this.setState({ inEdit: false, editedItem: false })
    if (itemId === 'new') {
      this.props.Frame.goTo(`/team/${teamId}/${listId}`)
    }
  }

  deleteItem = () => {
    this.props.deleteItem(this.props.item.id)
  }

  updateEditedItem = editedItem => {
    this.setState({ editedItem })
  }

  setTitle() {
    const listTitle = _.startCase(_.get(this.props, 'Frame.listId', ''))
    const itemTitle = _.get(this.props, 'item.title', '')
    document.title = `${itemTitle ? itemTitle : listTitle} - Impala`
  }

  render() {
    const { Frame } = this.props
    const item = _.get(this.props, 'item', false)
    const mode = this.state.inEdit ? 'edit' : 'view'
    const list = Lists[this.props.Frame.listId]
    const jobTitle = _.get(item, 'fields.jobtitle.value')
    const company = _.get(item, 'fields.company.value')
    const defaultConnections = [
      {
        listId: Frame.listId,
        itemId: Frame.itemId,
        title: item.title
      }
    ]
    if (item.companyFields) {
      defaultConnections.push({
        listId: 'companies',
        itemId: item.companyFields.id,
        title: item.companyFields.companyname.value
      })
    }
    return (
      <>
        {Frame.itemId && (
          <div className="Frame__profile">
            <div
              className={this.state.activeTab === 'activities' ? 'Profile Profile--show-activities' : 'Profile'}
              tabIndex="0">
              <div className={mode === 'view' ? 'Profile__contact' : 'Profile__contact Profile__contact--edit'}>
                {mode === 'view' && (
                  <>
                    <div className="Profile__contact-header">
                      <ProfileImage
                        size={128}
                        alt={`Image of ${item.title}`}
                        initials={item.initials}
                        imageUrl={_.get(item, 'photo.thumb256')}
                        className="Profile__contact-header__image"
                      />
                      <div className="Profile__contact-header__data">
                        <h2 className="Profile__contact-header__title">{item.title}</h2>
                        {(jobTitle || company) && (
                          <p className="Profile__contact-header__subtitle">
                            {jobTitle}
                            {jobTitle && company && ' at '}
                            {company && (
                              <Link
                                to={`/team/${Frame.teamId}/${company.listId}/${company.itemId}`}
                                className="Profile__contact-header__company-link">
                                {company.label}
                              </Link>
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="Profile__tabs">
                      <button
                        onClick={() => this.setState({ activeTab: 'profile' })}
                        className={
                          this.state.activeTab === 'profile'
                            ? 'Profile__tabs__tab Profile__tabs__tab--active'
                            : 'Profile__tabs__tab'
                        }>
                        Profile
                      </button>
                      <button
                        onClick={() => this.setState({ activeTab: 'activities' })}
                        className={
                          this.state.activeTab === 'activities'
                            ? 'Profile__tabs__tab Profile__tabs__tab--active'
                            : 'Profile__tabs__tab'
                        }>
                        Activities
                      </button>
                    </div>
                    <div className="Profile__contact-body">
                      <ContactDetails
                        item={item}
                        sections={list.view.sets}
                        Fields={Fields}
                        Frame={Frame}
                        editItem={this.editItem}
                      />
                    </div>
                  </>
                )}
                {mode === 'edit' && (
                  <>
                    <ContactEditor
                      Frame={Frame}
                      item={this.state.editedItem}
                      saveItem={this.saveItem}
                      updateEditedItem={this.updateEditedItem}
                    />
                  </>
                )}
                {this.state.inEdit && (
                  <div className="Profile__navbar">
                    <div>
                      <button className="Profile__navbar__button--delete" onClick={this.deleteItem}>
                        Delete
                      </button>
                    </div>
                    <div>
                      <React.Fragment>
                        <button className="Profile__navbar__button--discard" onClick={this.discardEdit}>
                          Discard edit
                        </button>
                        or
                        <button className="Profile__navbar__button--confirm" onClick={this.saveItem}>
                          Save
                        </button>
                      </React.Fragment>
                    </div>
                  </div>
                )}
              </div>
              <div className="Profile__activities">
                {Frame.itemId !== 'new' && (
                  <>
                    <h3 className="Activities__heading">Follow-ups</h3>
                    <FollowUps
                      Frame={Frame}
                      defaultConnections={defaultConnections}
                      itemId={Frame.itemId}
                      userId={false}
                    />
                    <h3 className="Activities__heading">Notes</h3>
                    <Notes Frame={Frame} defaultConnections={defaultConnections} />
                  </>
                )}
              </div>
              <Link to={`/team/${Frame.teamId}/${Frame.listId}`} className="Profile__close-flap">
                Close
              </Link>
            </div>
          </div>
        )}
      </>
    )
  }
}
export default Profile

function calculateTitleAndInitials(item, type) {
  let nameArray = []
  if (type === 'people') {
    nameArray = [_.get(item, 'fields.firstname.value', ''), _.get(item, 'fields.lastname.value', '')].filter(
      v => v !== ''
    )
  } else {
    nameArray = type === 'companies' ? [item.fields.companyname.value] : [item.fields.name.value]
  }
  const title = nameArray.join(' ')
  const initials =
    nameArray.length > 1
      ? nameArray
          .map(name => {
            return name.substr(0, 1).toUpperCase()
          })
          .join('')
      : nameArray[0]
          .replace(' ', '')
          .substr(0, 2)
          .toUpperCase()
  return { title, initials }
}

import React, { Component } from 'react'
import FocusBox from '../components/Utils/FocusBox'

class GoogleAuthPage extends Component {
  constructor() {
    super()

    const url = new URL(window.location.href)

    this.state = {
      error: url.searchParams.get('error'),
      code: url.searchParams.get('code')
    }
  }

  render() {
    return (
      <FocusBox>
        <p style={{ fontFamily: 'monospace' }}>{this.state.code}</p>
      </FocusBox>
    )
  }
}
export default GoogleAuthPage

import React, { Component } from 'react'
import firebase from './config/Firebase'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import {
  MailActionPage,
  Frame,
  LoginPage,
  SignupPage,
  CreateTeamPage,
  VerifyEMailPage,
  ResetPasswordPage,
  GoogleAuthPage
} from './pages'
import Sandbox from './components/Sandbox'

const db = firebase.firestore()
firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(err => {
    if (err.code === 'failed-precondition') {
      console.warn('Persistence failed due to multiple tabs')
    } else if (err.code === 'unimplemented') {
      console.warn('Persistence failed due to missing browser features')
    }
  })

class App extends Component {
  constructor() {
    super()

    this.state = {
      initilized: false,
      user: false,
      admin: false
    }

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('users')
          .doc(firebase.auth().currentUser.uid)
          .onSnapshot(doc => {
            this.setState({
              initilized: true,
              user: user.uid,
              userData: doc.data()
            })
          })
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(idTokenResult => {
            this.setState({ isAdmin: idTokenResult.claims.admin ? true : false })
          })
          .catch(error => {
            console.warn(error)
          })
      } else {
        this.setState({
          initilized: true,
          user: false
        })
      }
    })
  }

  render() {
    if (this.state.initilized) {
      if (firebase.auth().currentUser && this.state.userData) {
        if (firebase.auth().currentUser.emailVerified) {
          return (
            <BrowserRouter>
              <Switch>
                <Route path="/sandbox" exact render={() => <Sandbox />} />
                <Route
                  path="/team/:teamId/:listId/:itemId"
                  exact
                  render={props => <Frame {...props} user={this.state.userData} isAdmin={this.state.isAdmin} />}
                />
                <Route
                  path="/team/:teamId/:listId"
                  exact
                  render={props => <Frame {...props} user={this.state.userData} isAdmin={this.state.isAdmin} />}
                />
                <Route path="/createteam" render={props => <CreateTeamPage {...props} user={this.state.userData} />} />
                <Route path="/mailaction" render={props => <MailActionPage {...props} />} />
                <Route path="/forgotpassword" render={props => <ResetPasswordPage {...props} />} />
                <Route exact path="/login" component={LoginPage} setActiveUser={this.setActiveUser} />
                <Route path="/signup/:inviteId?" component={SignupPage} />
                <Route exact path="/googleauth" render={props => <GoogleAuthPage {...props} />} />
                <Route
                  path="*"
                  render={() => (
                    <Redirect
                      to={
                        this.state.userData.activeTeam
                          ? '/team/' + this.state.userData.activeTeam + '/people'
                          : '/createteam'
                      }
                    />
                  )}
                />
              </Switch>
            </BrowserRouter>
          )
        } else {
          // email is not verified
          return (
            <BrowserRouter>
              <Switch>
                <Route path="/mailaction" render={props => <MailActionPage {...props} />} />
                <Route exact path="/login" component={LoginPage} setActiveUser={this.setActiveUser} />
                <Route path="*" render={props => <VerifyEMailPage {...props} />} />
              </Switch>
            </BrowserRouter>
          )
        }
      } else {
        // user is not logged in
        return (
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={LoginPage} setActiveUser={this.setActiveUser} />
              <Route path="/signup/:inviteId?" component={SignupPage} />
              <Route path="/mailaction" render={props => <MailActionPage {...props} />} />
              <Route path="/forgotpassword" render={props => <ResetPasswordPage {...props} />} />
              <Route path="*" render={() => <Redirect to="/login" />} />
            </Switch>
          </BrowserRouter>
        )
      }
    } else {
      // not yet initilized
      return (
        <div className="loading">
          <a href="/" className="logo loading__logo">
            Impala
          </a>
          <div className="spinner">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )
    }
  }
}

export default App

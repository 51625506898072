import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

class MainNav extends Component {
  render() {
    const Frame = this.props.Frame
    const categories = _.get(Frame, 'team.categories', {})
    return (
      <ul className="MainNav">
        {['people', 'companies', 'cases'].map(listId => (
          <li key={listId}>
            <Link
              to={`/team/${Frame.teamId}/${listId}`}
              className={`MainNav__item--${listId} ${Frame.listId === listId && !Frame.categoryId ? 'active' : ''}`}>
              {_.startCase(listId)}
            </Link>
            {categories[listId] && (
              <ul className="MainNav__categories">
                {categories[listId].map(category => (
                  <li key={category.id}>
                    <Link
                      to={`/team/${Frame.teamId}/${listId}:${category.id}`}
                      className={`MainNav__categories__item ${Frame.categoryId === category.id ? 'active' : ''}`}>
                      {category.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    )
  }
}
export default MainNav

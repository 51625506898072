import React, { Component } from 'react'
import firebase from '../../config/Firebase'

class Account extends Component {
  render() {
    return (
      <>
        <h1>Account &amp; Profile</h1>
        <p>
          <strong>{firebase.auth().currentUser.displayName}</strong> {firebase.auth().currentUser.email}
        </p>
      </>
    )
  }
}
export default Account

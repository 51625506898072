import React, { Component } from 'react'
import Button from '../Forms/Button'

class Sync extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: true,
      googleAPI: {
        CLIENT_ID: '716832178985-es3a8vdgvp3ldpv5n58mjjgtggtvfi7e.apps.googleusercontent.com'
      }
    }
  }

  componentDidMount() {
    if (typeof window.gapi === 'undefined') {
      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/api.js'

      script.onload = () => {
        window.gapi.load('client:auth2', this.initGoogleAPI)
      }

      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }

  initGoogleAPI() {
    window.gapi.client
      .init({
        apiKey: 'AIzaSyD5Ziyo2CndL59n5DyJ3ZvF2NwG_F8HIB8',
        clientId: '716832178985-es3a8vdgvp3ldpv5n58mjjgtggtvfi7e.apps.googleusercontent.com',
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest'],
        scope: 'https://www.googleapis.com/auth/contacts.readonly'
      })
      .then(() => {
        console.log(window.gapi)
      })
  }

  updateSigninStatus(isSignedIn) {
    console.log('is signed in: ', isSignedIn)
  }

  requestGoogleAcces() {
    const config = {
      baseUri: 'https://accounts.google.com/o/oauth2/v2/auth',
      apiKey: 'AIzaSyD5Ziyo2CndL59n5DyJ3ZvF2NwG_F8HIB8',
      clientId: '716832178985-es3a8vdgvp3ldpv5n58mjjgtggtvfi7e.apps.googleusercontent.com',
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest'],
      scope: 'https://www.googleapis.com/auth/contacts',
      redirectUri: 'http://localhost:3000/googleauth'
    }

    const url = `${config.baseUri}?scope=${config.scope}&access_type=offline&include_granted_scopes=true&redirect_uri=${config.redirectUri}&response_type=code&client_id=${config.clientId}`

    window.open(url)
  }

  render() {
    return (
      <>
        <h1>Sync</h1>
        {this.state.loaded && <Button onClick={() => this.requestGoogleAcces()}>Sync with Google</Button>}
      </>
    )
  }
}
export default Sync

import React, { Component } from 'react'
import firebase from '../../../config/Firebase'
import { Object } from 'core-js'
import _ from 'lodash'

class AdminTeams extends Component {
  constructor(props) {
    super(props)

    this.state = {
      teams: [],
      newTeam: {
        name: '',
        id: ''
      }
    }
  }

  componentDidMount() {
    firebase
      .firestore()
      .collection('teams')
      .onSnapshot(snapshot => {
        const teams = []
        snapshot.forEach(doc => {
          const team = doc.data()
          team.id = doc.id
          teams.push(team)
        })
        this.setState({ teams })
      })
  }

  onTeamChange = e => {
    const newTeam = _.cloneDeep(this.state.newTeam)
    newTeam[e.target.name] = e.target.value
    this.setState({ newTeam })
  }

  onTeamSubmit = e => {
    const { newTeam } = this.state
    e.preventDefault()
    firebase
      .firestore()
      .collection('teams')
      .doc(newTeam.id)
      .set({ name: newTeam.name, members: {} })
      .then(() => {
        this.setState({
          newTeam: {
            name: '',
            id: ''
          }
        })
      })
  }

  render() {
    return (
      <>
        <h1>Teams</h1>
        <form onSubmit={this.onTeamSubmit} style={{ marginBottom: '1rem' }}>
          <input
            type="text"
            value={this.state.newTeam.name}
            name="name"
            onChange={this.onTeamChange}
            placeholder="Name"
          />
          <input type="text" value={this.state.newTeam.id} name="id" onChange={this.onTeamChange} placeholder="ID" />
          <button type="submit">Create</button>
        </form>
        <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
          {this.state.teams.map(team => (
            <li
              key={team.id}
              style={{ margin: '0 0 1rem', padding: '1rem', border: '1px #ccc solid', borderRadius: '5px' }}>
              <div>
                <strong>{team.name}</strong>
              </div>
              <small style={{ color: 'grey' }}>{team.id}</small>
              <div>
                Members:
                <ul style={{ listStyle: 'none', margin: 0, padding: 0, display: 'inline-block' }}>
                  {Object.keys(team.members).map(memberId => (
                    <li key={memberId} style={{ display: 'inline-block', marginLeft: '0.5rem' }}>
                      <strong>{team.members[memberId].name}</strong> ({memberId})
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </>
    )
  }
}
export default AdminTeams

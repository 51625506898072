import React, { Component } from 'react'

class Teams extends Component {
  constructor(props) {
    super(props)

    this.state = {
      teamInEdit: false,
      teamInEditData: false,
      teamInEditActiveInvites: false,
      inviteEMail: '',
      inviteTo: ''
    }
  }

  render() {
    const teams = this.props.user.teams
    return (
      <React.Fragment>
        <h1>Teams</h1>
        <ul>
          {Object.keys(teams).map(team => (
            <li key={team}>{teams[team].name}</li>
          ))}
        </ul>
      </React.Fragment>
    )
  }
}
export default Teams

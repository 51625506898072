import React, { Component } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import firebase from '../../config/Firebase'
import uuid from 'uuid/v4'
import _ from 'lodash'
import Button from '../Forms/Button'

const SortableList = SortableContainer(({ items, type, onLabelChange, deleteCategory }) => {
  return (
    <ul className="Settings__cards-list">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          type={type}
          onLabelChange={onLabelChange}
          deleteCategory={deleteCategory}
        />
      ))}
    </ul>
  )
})

const SortableItem = SortableElement(({ value, type, onLabelChange, deleteCategory }) => (
  <li className="Settings__card">
    <DragHandle />
    <input
      className="Settings__card__input"
      type="text"
      value={value.label}
      autoFocus={value.label === '' ? true : false}
      placeholder="Category Title"
      name={value.id}
      id={value.id}
      onChange={e => onLabelChange(e, type)}
    />
    <button className="Settings__card__close-button" type="button" onClick={() => deleteCategory(value.id, type)}>
      ×
    </button>
  </li>
))

const DragHandle = SortableHandle(() => <div className="Settings__card__handle">::</div>)

class Categories extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: {
        people: [],
        companies: [],
        cases: []
      }
    }
  }

  componentDidMount() {
    const categories = _.get(this.props.Frame, `team.categories`, false)
    if (categories) {
      this.setState({ categories: { ...this.state.categories, ...categories } })
    }
  }

  componentDidUpdate(prevProps) {
    const prevCategories = _.get(prevProps.Frame, 'team.categories')
    const categories = _.get(this.props.Frame, 'team.categories')
    if (!_.isEqual(prevCategories, categories) && categories) {
      this.setState({ categories: { ...this.state.categories, ...categories } })
    }
  }

  addCategory = type => {
    const id = uuid().substr(0, 8)
    const categories = this.state.categories
    categories[type].push({ id, label: '' })
    this.setState({ categories })
  }

  onLabelChange = (e, type) => {
    const { name, value } = e.target
    const index = _.findIndex(this.state.categories[type], v => v.id === name)
    const newCategories = _.set(this.state.categories, `${type}[${index}].label`, value)
    this.setState({ categories: newCategories })
  }

  onSortEnd = (from, to, type) => {
    const arr = this.state.categories[type]
    arr.splice(to, 0, arr.splice(from, 1)[0])
    this.setState({
      categories: _.set(this.state.categories, `${type}`, arr)
    })
  }

  deleteCategory = (id, type) => {
    const { categories } = this.state
    categories[type] = categories[type].filter(v => v.id !== id)
    this.setState({ categories })
  }

  saveCategories = e => {
    e.preventDefault()
    firebase
      .firestore()
      .collection('teams')
      .doc(this.props.Frame.teamId)
      .update({
        categories: this.state.categories
      })
  }

  render() {
    return (
      <form onSubmit={this.saveCategories}>
        <h1 className="Settings__heading-1">Categories</h1>
        {['people', 'companies', 'cases'].map(type => (
          <React.Fragment key={type}>
            <h2 className="Settings__heading-2">{_.startCase(type)}</h2>
            <SortableList
              items={_.get(this.state.categories, `${type}`, [])}
              type={type}
              lockOffset="0px"
              helperClass="sortableHelper"
              onLabelChange={this.onLabelChange}
              deleteCategory={this.deleteCategory}
              useDragHandle={true}
              onSortEnd={e => this.onSortEnd(e.oldIndex, e.newIndex, type)}
            />
            <div>
              {_.get(this.state.categories, `${type}`, []).length !== 0 ? (
                <button
                  className="Settings__cards-list__add-button"
                  type="button"
                  onClick={() => this.addCategory(type)}>
                  + New {_.startCase(type)} Category
                </button>
              ) : (
                <button
                  className="Settings__card Settings__card--empty"
                  type="button"
                  onClick={() => this.addCategory(type)}>
                  + Add your first {_.startCase(type)} category
                </button>
              )}
            </div>
          </React.Fragment>
        ))}
        <div style={{ textAlign: 'right' }}>
          <Button type="submit">Save</Button>
        </div>
      </form>
    )
  }
}
export default Categories
